<template>
  <el-drawer
    :visible="show"
    :append-to-body="true"
    destroy-on-close
    size="600px"
    @close="$emit('close')"
  >
    <div class="content-wrapper">
      <p class="text-primary-100 font-medium" style="font-size: 24px;">{{ displayData.title }}</p>

      <!-- 基本資訊 -->
      <div class="flex flex-col" style="margin-top: 16px; gap: 8px">
        <div class="flex items-center" style="gap: 4px">
          <span>{{ displayData.userName }}</span>
          <span>{{ displayData.userPhone }}</span>
        </div>

        <div class="flex items-center justify-between">
          <p>時間：{{ displayData.sellAt }}</p>
          <p>銷售紀錄編號：{{ displayData.orderNo }}</p>
        </div>
      </div>

      <!-- 商品資訊 -->
      <div>
        <div v-for="salesUnit in displaySalesUnitGroupItems" :key="salesUnit.id" class="sales-unit-group">
          <div class="sales-unit">
            {{ salesUnit.name }}
          </div>
          <div class="flex flex-col" style="gap: 12px">
            <div v-for="item in salesUnit.items" :key="item.id" class="flex flex-col" style="gap: 6px">
              <div class="row">
                <p>{{ item.name }} <span>x{{ item.quantity }}</span></p>
                <p class="font-medium flex-shrink-0" style="font-size: 18px;">$ {{ item.totalPrice }}</p>
              </div>

              <el-collapse-transition>
                <div class="flex flex-col" style="gap: 6px">
                  <div v-for="discount in item.discounts" :key="discount.id" class="row text-sm text-gray-60">
                    <p>{{ discount.name }}</p>
                    <p class="flex-shrink-0">-$ {{ discount.savePrice }}</p>
                  </div>
                </div>
              </el-collapse-transition>
            </div>
          </div>
        </div>
      </div>

      <!-- 整單折扣 -->
      <div v-if="showControl.block.orderDiscount">
        <el-divider />
        <div class="flex flex-col" style="gap: 12px">
          <div class="row font-medium">
            <p>整單折扣</p>
            <p class="flex-shrink-0" style="font-size: 18px;">-$ {{ displayData.totalOrderDiscountPrice }}</p>
          </div>

          <div class="flex flex-col" style="gap: 6px">
            <div v-for="discount in displayOrderDiscounts" :key="discount.id" class="row text-sm text-gray-60">
              <p>{{ discount.name }}</p>
              <p class="flex-shrink-0">-$ {{ discount.savePrice }}</p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showControl.block.appointmentInfo">
        <el-divider />
        <div class="flex flex-col" style="gap: 12px">
          <div v-for="item in displayAppointmentInfo" :key="item.type" class="row">
            <p>{{ item.name }}</p>
            <p class="font-medium flex-shrink-0" style="font-size: 18px;">{{ item.displayValue }}</p>
          </div>
        </div>
      </div>

      <!-- 付款方式 -->
      <div>
        <el-divider />
        <div class="flex flex-col" style="gap: 12px">
          <div class="row font-medium">
            <p>總額</p>
            <p class="flex-shrink-0">{{ displayData.orderTotalPrice }}</p>
          </div>

          <div class="flex flex-col" style="gap: 12px">
            <div v-for="payment in displayOrderPayments" :key="payment.id" class="row">
              <p>{{ payment.name }}</p>
              <p class="flex-shrink-0">$ {{ payment.amount }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 本次新增 -->
      <div v-if="showControl.block.addBenefits">
        <el-divider />
        <div class="flex flex-col" style="gap: 12px">
          <div>
            <p class="font-medium">本次新增</p>
          </div>

          <div class="flex flex-col" style="gap: 12px">
            <div v-for="item in displayAddBenefits" :key="item.id" class="row">
              <p>{{ item.name }}</p>
              <p class="flex-shrink-0">{{ item.prefix }} {{ item.quantity }} {{ item.suffix }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 本次扣除 -->
      <div v-if="showControl.block.usedBenefits">
        <el-divider />
        <div class="flex flex-col" style="gap: 12px">
          <div>
            <p class="font-medium">本次扣除</p>
          </div>

          <div class="flex flex-col" style="gap: 12px">
            <div v-for="item in displayUsedBenefits" :key="item.id" class="row">
              <p>{{ item.name }}</p>
              <p class="flex-shrink-0">{{ item.prefix }} {{ item.quantity }} {{ item.suffix }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 獲得回饋 -->
      <div>
        <el-divider />
        <div class="flex flex-col" style="gap: 12px;">
          <div>
            <p class="font-medium">本次可獲得回饋</p>
          </div>

          <div v-if="showControl.block.rewards" class="flex flex-col" style="gap: 12px;">
            <div v-for="reward in displayRewards" :key="reward.rewardType" class="row">
              <p>{{ reward.name }}</p>
              <p class="flex-shrink-0">{{ reward.displayValue }}</p>
            </div>
          </div>

          <div class="row items-start" style="gap: 70px; align-items: start;">
            <div class="flex items-center" style="gap: 8px">
              <p class="flex-shrink-0">備註</p>
              <div
                v-if="showControl.btn.createNote"
                class="text-primary-100 underline cursor-pointer"
                @click="modal.noteEdit = true"
              >
                填寫備註
              </div>
            </div>
            <div class="flex items-start" style="gap: 8px">
              <div class="break-all">
                {{ displayData.note }}
              </div>
              <div
                v-if="showControl.btn.editNote"
                class="text-primary-100 underline cursor-pointer flex-shrink-0"
                @click="modal.noteEdit = true"
              >
                編輯
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div>
        <el-divider />
        <div class="flex flex-col" style="gap: 24px;">
          <div class="row">
            <p>新增紀錄操作單位</p>
            <p>{{ displayData.creatorName }}</p>
          </div>
          <div class="row">
            <p>建立管道</p>
            <p>{{ displayData.createOrigin }}</p>
          </div>
          <div class="row">
            <p>銷售紀錄建立時間</p>
            <p>{{ displayData.createdAt }}</p>
          </div>
        </div>
      </div>

      <!-- QRCode -->
      <div v-if="showControl.block.qrcode" class="flex flex-col" style="gap: 16px; margin-top: 16px;">
        <p class="text-primary-100 font-medium">交易 QRcode</p>
        <div v-if="showControl.paymentQRcode" class="flex justify-center justify-center">
          <img :src="paymentQrcode" alt="QR Code" style="width: 179px;">
        </div>
        <p v-if="showControl.qrcodeInvalid">交易逾時 QRcode 已作廢</p>
      </div>

      <div class="flex justify-end" style="margin-top: 80px;">
        <BaseElButton style="width: 100px" plain @click="$emit('close')">
          返回
        </BaseElButton>
        <BaseElButton
          v-if="showControl.btn.void"
          style="width: 100px"
          type="primary"
          @click="onOrderVoid"
        >
          作廢
        </BaseElButton>
      </div>
    </div>

    <EditSalesRecordNoteModal
      v-if="modal.noteEdit"
      :data="data"
      @close="modal.noteEdit = false"
      @refresh="onRefresh"
    />
    <SalesRecordCancelModal
      v-if="modal.void"
      :record="data"
      :configData="configData"
      @close="modal.void = false"
      @refresh="onRefresh"
    />
  </el-drawer>
</template>

<script>
import { paymentOptionsConfig } from '@/config/payment'
import { formatDate } from '@/utils/date'
import { filter, includes, uniqueId, map, forEach, get, isEmpty, find } from 'lodash'

import { computed, defineComponent, inject, onBeforeMount, onBeforeUnmount, reactive, ref } from 'vue'
import EditSalesRecordNoteModal from './EditSalesRecordNoteModal.vue'
import { useQRCode } from '@vueuse/integrations/useQRCode'
import SalesRecordCancelModal from './SalesRecordCancelModal.vue'
import { salesRecordCreateOriginDeviceConfig } from '@/config/sales'
import { useShop } from '@/use/shop'
import { formatBenefitChangeLogs } from '@/use/useSalesPOS'

export default defineComponent({
  name: 'SalesRecordDetailDrawer',
  components: {
    EditSalesRecordNoteModal,
    SalesRecordCancelModal,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const configData = inject('configData')
    const { defaultShopPoint } = useShop()
    const show = ref(false)
    const modal = reactive({
      noteEdit: false,
      void: false,
    })
    const paymentQrcode = useQRCode(get(props.data, 'SalesPaymentOrder.code'))
    const displayData = computed(() => {
      const sellAt = get(props.data, 'sellAt')
      const data = {
        title: '銷售紀錄',
        createdAt: formatDate(get(props.data, 'createdAt')),
        sellAt: sellAt ? formatDate(get(props.data, 'sellAt')) : '-',
        orderNo: get(props.data, 'orderNo'),
        note: get(props.data, 'note'),
      }

      const totalItemDiscountPrice = get(props.data, 'totalItemDiscountPrice')
      const totalDiscountPrice = get(props.data, 'totalDiscountPrice')
      const totalItemsPrice = get(props.data, 'totalItemsPrice')

      data.totalOrderDiscountPrice = totalDiscountPrice - totalItemDiscountPrice

      data.orderTotalPrice = totalItemsPrice - totalDiscountPrice

      // 建立管道
      const origin = get(props.data, 'origin')
      let displayOrigin
      if (origin) {
        if (origin === 'admin') displayOrigin = '後台'
        else if (origin === 'client') displayOrigin = '顧客'
        else if (origin === 'admin_past') displayOrigin = '後台跨日補登'
      }
      const originDevice = get(props.data, 'originDevice')

      data.createOrigin = `${displayOrigin} - ${get(salesRecordCreateOriginDeviceConfig, `${originDevice}.label`, '')}`

      // 建立者
      const creator = get(props.data, 'Creator')
      if (creator) {
        data.creatorName = get(creator, 'name')
      }

      const userInfo = get(props.data, 'Member.UserInfo')
      if (userInfo) {
        data.userName = get(userInfo, 'name')
        data.userPhone = get(userInfo, 'phone')
      } else {
        data.userName = '非會員'
      }

      return data
    })
    const isEzaio = computed(() => {
      return Boolean(find(get(props.data, 'SalesRecordPayments'), { type: 'ezAIOTrust' }))
    })

    const useInvalidPassword = computed(() => get(configData.value, 'useInvalidPassword'))

    // ?? 顯示的服務人員群組商品
    const displaySalesUnitGroupItems = computed(() => {
      const groupedItems = {}

      forEach(get(props.data, 'SalesRecordItems'), item => {
        const salesUnitId = item.SalesUnitId
        if (!groupedItems[salesUnitId]) {
          groupedItems[salesUnitId] = {
            id: salesUnitId,
            name: get(item.SalesUnit, 'name', '無銷售人員'),
            items: [],
          }
        }
        groupedItems[salesUnitId].items.push({
          name: get(item, 'name'),
          totalPrice: get(item, 'totalPrice'),
          id: get(item, 'id'),
          discounts: map(get(item, 'itemDiscounts'), (discount) => {
            return {
              name: get(discount, 'name'),
              savePrice: get(discount, 'savePrice'),
              id: uniqueId(),
            }
          }),
          quantity: get(item, 'quantity'),
        })
      })

      return groupedItems
    })

    // ?? 顯示的整單折扣
    const displayOrderDiscounts = computed(() => {
      const omitType = ['totalItemDiscount']
      const discounts = filter(get(props.data, 'SalesRecordDiscounts', []), (item) => {
        return !includes(omitType, item.type)
      })
      return map(discounts, (item) => {
        return {
          name: item.name,
          savePrice: item.savePrice,
          id: item.id,
        }
      })
    })

    // ?? 顯示的付款方式
    const displayOrderPayments = computed(() => {
      const omitType = ['paidDeposit']
      const payments = filter(get(props.data, 'SalesRecordPayments'), (item) => {
        return !includes(omitType, item.type)
      })

      const extendPaymentOptionsConfig = {
        ...paymentOptionsConfig,
        credit: {
          name: '信用卡',
          value: 'credit',
        },
        other: {
          name: '其他',
          value: 'other',
        },
      }
      extendPaymentOptionsConfig.offline.name = '現場付款'
      extendPaymentOptionsConfig.atm.name = '轉帳'

      const paymentList = map(payments, (item) => {
        const paymentName = get(extendPaymentOptionsConfig, `${item.type}.name`, '其他')
        return {
          name: paymentName,
          amount: item.amount,
          id: item.id,
        }
      })

      const totalPaymentPrice = get(props.data, 'totalPaymentPrice')

      const cashPaid = displayData.value.orderTotalPrice - totalPaymentPrice
      if (cashPaid > 0) {
        paymentList.push({
          name: '現金',
          amount: cashPaid,
          id: 'cash',
        })
      }

      return paymentList
    })

    // ?? 顯示的回饋
    const displayRewards = computed(() => {
      const rewards = get(props.data, 'RewardActivityRecords')
      const oldCashback = get(props.data, 'totalAddCashback')

      const groupedRewards = {}
      const rewardName = {
        POINT: get(defaultShopPoint.value, 'name', '點數'),
        CASHBACK: '檔期回饋金',
      }
      forEach(rewards, reward => {
        const rewardType = get(reward, 'rewardType')
        if (!groupedRewards[rewardType]) {
          groupedRewards[rewardType] = {
            rewardType,
            rewardAmount: 0,
            name: get(rewardName, rewardType, '其他'),
          }
        }
        groupedRewards[rewardType].rewardAmount += get(reward, 'rewardAmount')
        let displayValue
        if (rewardType === 'POINT') {
          displayValue = `${get(groupedRewards[rewardType], 'rewardAmount')} ${get(defaultShopPoint.value, 'unitName', '點')}`
        } else if (rewardType === 'CASHBACK') {
          displayValue = `$ ${get(groupedRewards[rewardType], 'rewardAmount')}`
        }
        groupedRewards[rewardType].displayValue = displayValue
      })

      if (oldCashback) {
        groupedRewards.oldCashback = {
          rewardType: 'oldCashback',
          rewardAmount: oldCashback,
          name: '回饋金',
          displayValue: `$ ${oldCashback}`,
        }
      }
      return groupedRewards
    })

    const displayAppointmentInfo = computed(() => {
      // 訂金、堂票、預約結帳
      const data = {}
      const paidDeposit = find(get(props.data, 'SalesRecordPayments'), { type: 'paidDeposit' })

      if (paidDeposit) {
        data.deposit = {
          name: '訂金',
          amount: get(paidDeposit, 'amount', 0),
          displayValue: `$ ${get(paidDeposit, 'amount', 0)}`,
          type: 'deposit',
        }
      }

      return data
    })

    // !! 顯示的新增權益
    const displayAddBenefits = computed(() => {
      return formatBenefitChangeLogs(get(props.data, 'changeItemLogs'), 'add', {
        shopPoint: defaultShopPoint.value,
      })
    })

    // !! 顯示的使用權益
    const displayUsedBenefits = computed(() => {
      return formatBenefitChangeLogs(get(props.data, 'changeItemLogs'), 'spend', {
        shopPoint: defaultShopPoint.value,
      })
    })

    const showControl = computed(() => {
      const controls = {
        block: {
          orderDiscount: false,
          rewards: false,
          qrcode: false,
          appointmentInfo: false,
          addBenefits: false,
          usedBenefits: false,
        },
        btn: {
          createNote: false,
          editNote: false,
          cancel: false, // 返回按鈕
          void: false, // 作廢按鈕
        },
        paymentQRcode: false,
        qrcodeInvalid: false,
      }

      if (displayAddBenefits.value.length) {
        controls.block.addBenefits = true
      }

      if (displayUsedBenefits.value.length) {
        controls.block.usedBenefits = true
      }

      if (displayOrderDiscounts.value.length > 0) {
        controls.block.orderDiscount = true
      }

      if (!isEmpty(displayRewards.value)) {
        controls.block.rewards = true
      }

      if (displayData.value.note) {
        controls.btn.createNote = false
        controls.btn.editNote = true
      } else {
        controls.btn.createNote = true
        controls.btn.editNote = false
      }

      const paymentOrderStatus = get(props.data, 'SalesPaymentOrder.status')
      if (paymentOrderStatus === 'pending') {
        controls.block.qrcode = true
        controls.paymentQRcode = true
      } else if (paymentOrderStatus === 'fail') {
        controls.block.qrcode = true
        controls.qrcodeInvalid = true
      }

      if (!isEzaio.value && ['pending', 'complete'].includes(get(props.data, 'status'))) {
        controls.btn.void = true
      }

      if (!isEmpty(displayAppointmentInfo.value)) {
        controls.block.appointmentInfo = true
      }

      return controls
    })

    const onOrderVoid = () => {
      modal.void = true
    }

    const onRefresh = () => {
      emit('refresh')
    }

    onBeforeMount(() => {
      setTimeout(() => {
        show.value = true
      }, 100)
    })

    onBeforeUnmount(() => {
      show.value = false
    })

    return {
      modal,
      displayData,
      showControl,
      show,
      displaySalesUnitGroupItems,
      displayOrderDiscounts,
      displayOrderPayments,
      displayRewards,
      paymentQrcode,
      onRefresh,
      onOrderVoid,
      configData,
      displayAppointmentInfo,
      displayAddBenefits,
      displayUsedBenefits,
    }
  },
})
</script>

<style lang="postcss" scoped>
.row {
  @apply flex items-start justify-between gap-[8px];
}

.sales-unit {
  @apply bg-primary-30 py-[4.5px] px-[10px];
}

.sales-unit-group {
  @apply flex flex-col gap-[18px] mt-[16px];
}

.content-wrapper {
  @apply px-[36px];
}
</style>
